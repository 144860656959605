@import "@primer/css/support/variables/typography.scss";

$proximanova-bold: "ProximaNova-Bold";
$proximanova-light: "ProximaNova-Light";
$proximanova-regular: "ProximaNova-Regular";
$proximanova-thin: "ProximaNova-Thin";

$poppins-black-italic: "Poppins-BlackItalic";
$poppins-black: "Poppins-Black";
$poppins-bold-italic: "Poppins-BoldItalic";
$poppins-bold: "Poppins-Bold";
$poppins-extra-bold-italic: "Poppins-ExtraBoldItalic";
$poppins-extra-bold: "Poppins-ExtraBold";
$poppins-extra-light-italic: "Poppins-ExtraLightItalic";
$poppins-extra-light-: "Poppins-ExtraLight";
$poppins-italic: "Poppins-Italic";
$poppins-light-italic: "Poppins-LightItalic";
$poppins-light: "Poppins-Light";
$poppins-medium-italic: "Poppins-MediumItalic";
$poppins-medium: "Poppins-Medium";
$poppins-regular: "Poppins-Regular";
$poppins-semi-bold-italic: "Poppins-SemiBold-Italic";
$poppins-semi-bold: "Poppins-SemiBold";
$poppins-thin-italic: "Poppins-ThinItalic";
$poppins-thin: "Poppins-Thin";

$body-font: $poppins-regular, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$body-letter-spacing: 1px;
