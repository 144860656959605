@import "../support/variables/index.scss";

button {
  position: relative;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  appearance: none;
}

.btn {
  color: $white;
  font-family: $poppins-black;
  letter-spacing: 2.36px;
  transition: background-color 160ms ease-out;
  text-transform: uppercase;
  size: 14px;

  &:not(.btn-secondary) {
    background-color: $primary !important;
    background-image: none;
    border: none !important;
    border-radius: 3px;

    &:hover {
      background-color: $primary-dark !important;
      background-image: none !important;
      color: $white !important;
    }

    &:disabled, &[disabled] {
      background-color: $primary !important;
      color: $white !important;
      opacity: 30% !important;
    }
  }
}

.btn-primary, button.primary {
  @include btn-solid($text-white, $red-400, $red-500);

  &:hover {
    background-color: $red-600 !important;
    background-image: none !important;
    color: $white !important;
  }
}
