@import "@uppy/core/dist/style";
@import "@uppy/status-bar/dist/style";
@import "../support/variables/index.scss";

.uppy-StatusBar {
  &.is-complete {
    .uppy-StatusBar {
      &-progress {
        background-color: $red-400;
      }

      &-statusIndicator {
        color: $blue-600;
      }
    }
  }

  &.is-waiting {
    .uppy-StatusBar {
      &-progress {
        background-color: $red-400;
      }

      &-actionBtn--upload {
        @include btn-solid($text-white, $red-400, $red-500);

        &:hover {
          background-color: $red-600;
          background-image: none;
        }
      }
    }
  }
}

.uppy-StatusBar-progress {
  border-radius: 50%;
  margin-top: 0.25rem;
}
