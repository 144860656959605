@import "../support/variables/typography";

@mixin proxima-nova-font-face($family, $filename) {
  font-family: $family;
  src: url("//#{$cdn-domain-name}/font/proxima-nova/#{$filename}") format('opentype');
}

@font-face { @include proxima-nova-font-face($proximanova-bold, "bold.otf"); }
@font-face { @include proxima-nova-font-face($proximanova-light, "light.otf"); }
@font-face { @include proxima-nova-font-face($proximanova-regular, "regular.otf"); }
@font-face { @include proxima-nova-font-face($proximanova-thin, "thin.otf"); }
