@import "../support/variables/index.scss";

.uppy-FileInput-input {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;

}

.uppy-FileInput-label {
  cursor: pointer;
  outline: 1px dotted $gray-200;
  outline: -webkit-focus-ring-focuscolor auto 5px;
  transition: background-color 300ms ease-out;

  * {
    pointer-events: none;
  }

  @include btn-solid($text-white, $red-400, $red-500);

  .icon {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  span {
    padding: 0 0.5rem;
    vertical-align: middle;
  }

  &:hover {
    background-color: $red-600;
    background-image: none;
  }
}
