@import "@primer/css/support/variables/color-system.scss";

$gray-200: #e7e7e7;
$gray-300: #b9b9b9;

$red-500: #ed1c24;
$red-600: #c41016;

$red: $red-500;

$white: #ffffff;
$black: #16161d; // overload '@primer/css' $black definition
$dark: $black;
$light: #f8f4f4;
$primary: #44007c;
$primary-dark: #28004B;
$secondary: #ff5500;
