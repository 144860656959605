@import 'pure-react-carousel/dist/react-carousel.es';

.carousel {
  padding-bottom: 2.3rem;
  position: absolute;

  &__dot-group {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);
  }

  &__dot {
    -webkit-appearance: none;
    background-color: #cccccc;
    border: none;
    border-radius: 1000px;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    height: 0.5625rem;
    float: none;
    margin-right: 6px;
    padding: 0;
    width: 0.5625rem;

    &--selected {
      background-color: #999999;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
