@import "../support/variables/index.scss";

.uppy-Dashboard {
  &-browse {
    border-bottom: 0px solid $red-400;
    transition: border-bottom 160ms ease-in;
    cursor: pointer !important;
    &:hover, &:focus, &:active {
      border-bottom: 2px solid $red-500;
      transition: border-bottom 160ms ease-out;
    }
  }
}

.uppy-DashboardContent {
  &-addMore, &-back {
    background-color: $gray-200;
    background-image: none;
    border: none;
    border-radius: 1px;
    color: $gray-900;
    font-family: $body-font;
    letter-spacing: $body-letter-spacing;
    font-weight: 100;
    transition: background-color 300ms ease-out;

    &:hover {
      color: $red-400;
      //background-color: $gray-300;
      background-image: none;
    }
  }

  &-back  {
    @include btn-solid($text-white, $red-500, $red-500);

    &:hover {
      background-color: $red-600;
      color: $white;
      background-image: none;
    }
  }

  &-bar {
    background-color: $black;
    color: $white;
  }

  [button] {
    cursor: pointer;
  }
}

.uppy-Dashboard-inner {
  border: none;
}

.uppy-DashboardItem {
  &.is-complete {
    .progress {
      stroke: $blue-500;
      fill: $blue-500;
    }
  }
}

.uppy-Root {
  .uppy-DashboardAddFiles {
    border: none;
    border-radius: 0;
    border-top: 1px solid $red-400;
    box-shadow: inset 0px 2px 2px -2px $red-400;
    margin: 0;
  }
}

.UppyIcon {
  cursor: pointer;
}
