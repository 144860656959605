.uppy-FileInput-container {
  margin-bottom: 15px; }

.uppy-FileInput-btn {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  font-family: sans-serif;
  font-size: 0.85em;
  padding: 10px 15px;
  color: #14457f;
  border: 1px solid #14457f;
  border-radius: 8px;
  cursor: pointer; }
  .uppy-FileInput-btn:hover {
    background-color: #14457f;
    color: #fff; }
