@import "../support/variables/typography";

@mixin poppins-font-face($family, $filename) {
  font-family: $family;
  src: url("//#{$cdn-domain-name}/font/poppins/#{$filename}") format('opentype');
}

@font-face { @include poppins-font-face($poppins-black-italic, "black-italic.otf"); }
@font-face { @include poppins-font-face($poppins-black, "black.otf"); }
@font-face { @include poppins-font-face($poppins-bold-italic, "bold-italic.otf"); }
@font-face { @include poppins-font-face($poppins-bold, "bold.otf"); }
@font-face { @include poppins-font-face($poppins-extra-bold-italic, "extra-bold-italic.otf"); }
@font-face { @include poppins-font-face($poppins-extra-bold, "extra-bold.otf"); }
@font-face { @include poppins-font-face($poppins-extra-light-italic, "extra-light-italic.otf"); }
@font-face { @include poppins-font-face($poppins-extra-light-, "extra-light.otf"); }
@font-face { @include poppins-font-face($poppins-italic, "italic.otf"); }
@font-face { @include poppins-font-face($poppins-light-italic, "light-italic.otf"); }
@font-face { @include poppins-font-face($poppins-light, "light.otf"); }
@font-face { @include poppins-font-face($poppins-medium-italic, "medium-italic.otf"); }
@font-face { @include poppins-font-face($poppins-medium, "medium.otf"); }
@font-face { @include poppins-font-face($poppins-regular, "regular.otf"); }
@font-face { @include poppins-font-face($poppins-semi-bold-italic, "semi-bold-italic.otf"); }
@font-face { @include poppins-font-face($poppins-semi-bold, "semi-bold.otf"); }
@font-face { @include poppins-font-face($poppins-thin-italic, "thin-italic.otf"); }
@font-face { @include poppins-font-face($poppins-thin, "thin.otf"); }
